import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import { motion } from "framer-motion"
import Modal from "../../components/Modal";
import Marquee from "react-fast-marquee";
import { fetcher } from '../../helpers/processRequest';
import { GET } from '../../utils/requestActionConstants';
import { API_ROUTE } from '../../utils/commonConstants';
import useSWR from 'swr'

function Home() {
    const [show, setShow] = useState(false);
    const [showMarqueeBanners, setShowMarqueeBanners] = useState(false);
    const postsData = useSWR({method: GET, url: API_ROUTE + '/posts'} , fetcher)

    let featuredImages = [
        {id: 1, url: 'https://sms.clcsl.edu.lk/serverclc/upload/website/welcome/carrosal/1.jpeg', alt: ''},
        {id: 2, url: 'https://sms.clcsl.edu.lk/serverclc/upload/website/welcome/carrosal/2.jpeg', alt: ''},
        {id: 3, url: 'https://sms.clcsl.edu.lk/serverclc/upload/website/welcome/carrosal/3.jpeg', alt: ''},
        {id: 4, url: 'https://sms.clcsl.edu.lk/serverclc/upload/website/welcome/carrosal/4.jpg', alt: ''},
    ];

    let events = [
        
    ];

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        updateMarqueeBannerVisibility();
    }, []);

    const updateMarqueeBannerVisibility = () => {
        setTimeout(() => {
            setShowMarqueeBanners(true)
        }, 2000)
    }

    const handleWelcomeMessageModal = () => {
        setShow(false);
    }

    return (
        <div>
            <div className={'relative overflow-hidden shadow-md fade'} style={{height: '70vh', backgroundImage: `url("https://sms.clcsl.edu.lk/serverclc/upload/website/welcome/index.jpeg")`, backgroundPosition: 'left', backgroundSize: 'cover'}}>
                <div className={'absolute z-10 bottom-10 opacity-1 right-10 left-10 bg-white/90 rounded-lg overflow-hidden ring-1 ring-slate-900/5 p-8'}>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 1,
                            ease: [0, 0.71, 0.2, 1.01]
                        }}
                        viewport={{ once: true, amount: 0.8 }}
                    >
                        <div className={'text-2xl leading-normal'}>Your Bright Future...</div>
                        <div className={'text-5xl text-red-800 pt-2'}>OUR MISSION</div>
                        <div className={'text-xl pt-5 text-blue-900'}>To give good learning environment to the girls who are looking for a suitable place to pursue their higher study in a safe & Islamic environment</div>
                    </motion.div>
                </div>
                <div className={'flex flex-row'}>
                    {showMarqueeBanners ?
                        <Marquee>
                            <div className={'flex flex-row main-area-marquee-fade'}>
                                {featuredImages.map(image => {
                                    return (
                                        <div className={'w-full'} key={image.id} style={{backgroundImage: `url(${image.url})`, backgroundSize: 'cover', height: '70vh', minWidth: '600px', backgroundPosition: 'center'}}></div>
                                    )
                                })}
                            </div>
                        </Marquee>
                        : ""
                    }

                </div>
            </div>

            <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                    duration: 1,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
                viewport={{ once: true, amount: 0.8 }}
            >
                <div className={'w-full shadow-md mx-auto p-12 border-b-1 border-gray-500'}>
                    <div className={'text-3xl'}>Advanced Level & Professional Studies</div>
                    <div className={'pt-2'}>
                        The best place for girls to focus on their Advanced Level studies without any trouble, as the school provides a safe and secure nature friendly environment with accommodation facilities inside the school environment.
                    </div>
                    <div className={'pt-5'}>
                        <Link to={"/online-application"} className={'py-2 px-3 bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}>JOIN NOW!</Link>
                    </div>
                </div>
            </motion.div>

            <motion.div
                initial={{ opacity: 0, y: -250 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                    duration: 1,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
            >
                <div className={'flex flex-wrap w-full shadow-md mx-auto p-10 border-b-1 border-gray-500 align-middle'}>
                    <div className={'grow p-8'}>
                        <img className={'shadow-md rounded-md m-auto'} width={400} alt={'About'} src={'https://sms.clcsl.edu.lk/serverclc/upload/wp-content/uploads/2021/05/aboutus.jpg'} />
                    </div>
                    <div className={'md:basis-1/2'}>
                        <div className={'text-3xl'}>About Us</div>
                        <div className={'pt-2'}>
                            We will focus on the advanced level commerce stream in English medium & Science Stream. These streams will be segregated separately. The period of the course will be limited to 2 years until the students sit for the Advanced Level Examination,
                        </div>
                        <div className={'pt-2'}>
                            The commerce stream in English medium will give an opportunity for the girls who pursued their Ordinary Level Exams in English Medium to continue their Advanced Level too in the same language. Also, the students in Tamil or Sinhala medium will be couched to sit for the A/L's in English Medium.
                        </div>
                        <div className={'pt-2'}>
                            The science stream will provide the facilities to the girls to start their Advanced Level studies in a safe and peaceful environment.
                        </div>
                        <div className={'pt-5'}>
                            <Link to={"/online-application"} className={'py-2 px-3 bg-blue-500 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}>Apply Now!</Link>
                        </div>
                    </div>
                </div>
            </motion.div>

            <div className={'flex flex-wrap md:flex-row-reverse w-full shadow-md mx-auto py-10 px-5 md:px-10 border-b-1 border-gray-500 align-middle'}>
                <div className={'md:basis-1/2 my-10 md:px-8'}>
                    <motion.div
                        initial={{ opacity: 0, y: 150 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 1,
                            ease: [0, 0.71, 0.2, 1.01]
                        }}
                    >
                        <div className={'text-3xl shadow-md rounded p-3 bg-green-500 text-white'}>Our Achievements</div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-green-50'}>
                            <div className={'text-2xl'}> No. 1 in Kegalle District</div>
                            <div className={'pt-2'}>Ilma Anwar of Bio Science 2021 got the No. 1 in Kegalle District 2021 (2022) GCE A/L Examination (Bio Science Stream)</div>
                        </div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-green-50'}>
                            <div className={'text-2xl'}> Island Rank No. 1 </div>
                            <div className={'pt-2'}>Ceylon Ladies' Campus got the Island Rank No.1 in AAT Intermediate Level Examinations</div>
                        </div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-green-50'}>
                            <div className={'pt-2'}>Helping over 60 students to get into universities each year</div>
                        </div>
                    </motion.div>
                </div>
                <div className={'md:basis-1/2 my-10 md:px-8'}>
                    <motion.div
                        initial={{ opacity: 0, y: -150 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 1,
                            ease: [0, 0.71, 0.2, 1.01]
                        }}
                    >
                        <div className={'text-3xl shadow-md rounded p-3 bg-indigo-500 text-white'}>Our Courses</div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-indigo-50'}>
                            <div className={'text-2xl'}> 1. Biological Science </div>
                            <div className={'pt-2'}>GCE Advanced Level Science Stream subjects combination with Biology, Physics and Chemistry</div>
                        </div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-indigo-50'}>
                            <div className={'text-2xl'}> 2. Physical Science </div>
                            <div className={'pt-2'}>GCE Advanced Level Science Stream subjects combination with Combined Maths, Physics and Chemistry</div>
                        </div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-indigo-50'}>
                            <div className={'text-2xl'}> 3. Business Studies </div>
                            <div className={'pt-2'}>GCE Advanced Level Commerce Stream + AAT, Subjects combination with Business Studies, Accounting and Economics or ICT</div>
                        </div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-indigo-50'}>
                            <div className={'text-2xl'}> 4. Basic Computer Applications </div>
                            <div className={'pt-2'}>GCE Advanced Level General Information Technology subject with fundamentals of software programs</div>
                        </div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-indigo-50'}>
                            <div className={'text-2xl'}> 5. English Intermediate Level </div>
                            <div className={'pt-2'}>Three month duration English course </div>
                        </div>

                        <div className={'pt-2 shadow-md rounded p-3 mt-4 bg-indigo-50'}>
                            <div className={'text-2xl'}>6. Tharbiyyah</div>
                            <div className={'pt-2'}>Basic Islamic Studies and Practices to improve their personalities  </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            <div className={'w-full shadow-md mx-auto md:p-12 border-b-1 border-gray-500'}>
                <div className={'text-3xl text-3xl md:m-auto m-4 shadow-md rounded p-3 bg-slate-300'}>Events & Blogs</div>
                <div className={'flex flex-col md:flex-row flex-wrap pt-2'}>
                    {
                        !postsData.isLoading && postsData.data.map(event => {
                            return (
                                <div className={'p-2 md:w-1/2'} key={event.id}>
                                    <div className={'shadow-md p-4 rounded-l'}>
                                        <div className={'text-2xl bg-gray-50 p-2 py-2'}>{event.title}</div>
                                        <div className={'rounded overflow-hidden'}><img alt={'event'} src={event.main_img_url} /></div>
                                        <div className={'py-4'}>{event.body}</div>
                                        <div className={'flex flex-wrap'}>
                                            {
                                                event.images.map(image => {
                                                    return (<img loading='lazy' alt={image.id + '-' + event.id + '-' + event.title} className={'w-1/2'} key={image.id} src={image.image_url} />)
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    {
                        postsData.isLoading ? 
                            <div className='flex' style={{flexWrap: 'wrap'}}>
                                <div className={'animate-pulse m-auto p-2'}>
                                    <div className={'shadow-md p-4 rounded-l my-5 w-screen md:w-full'} style={{width: '80vw'}}>
                                        <div className={'rounded text-2xl bg-slate-200 bg-gray-50 p-2 py-2 my-5'} style={{height: 200}}></div>
                                        <div className={'rounded bg-slate-200 overflow-hidden my-2'}></div>
                                        <div className={'rounded py-4 bg-slate-200 my-2'}></div>
                                        <div className={'rounded flex flex-wrap my-5'} style={{width: '100%'}}>
                                            <div className={'w-1/2'}><div className='bg-slate-200 rounded' style={{height: 200}}></div></div>
                                            <div className={'w-1/2'}><div className='bg-slate-200 rounded ml-2 mb-2' style={{height: 200}}></div></div>
                                            <div className={'w-1/2'}><div className='bg-slate-200 rounded' style={{height: 200}}></div></div>
                                            <div className={'w-1/2'}><div className='bg-slate-200 rounded ml-2 mb-2' style={{height: 200}}></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : ''
                    }
                </div>

                <div className={'text-center p-10 '}>Visit our facebook page: <a  href={'https://web.facebook.com/CLC.SriLanka'} target={'_blank'} rel="noreferrer" className={'text-blue-400'}>Click Here!</a> </div>
            </div>

            <Modal show={show} onHide={handleWelcomeMessageModal}>
                <div>
                    <div className={'text-center'}>
                        <div className={'text-3xl'}>CALLING APPLICATIONS FOR GCE A/L STREAM 2025</div>
                        <div className={'flex justify-center mt-3 mb-10'}>
                            <button className={'mx-1 py-2 px-3 bg-blue-600 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'} onClick={handleWelcomeMessageModal}>Refer Web Page!</button>
                            <span className={'pt-1'}>or</span>
                            <button className={'mx-1 py-2 px-3  bg-green-700 text-white text-sm font-semibold rounded-md shadow-lg shadow-blue-500/50 focus:outline-none'}><Link to={'/online-application'}> Apply Now! </Link></button>
                        </div>
                    </div>
                    <div className={'text-center'}>
                        <div className={'text-2xl'}>Biological and Physical Science Stream</div>
                        <div>(Tamil Medium)</div>
                        <div className={'text-2xl pt-10'}>Commerce Stream + AAT</div>
                        <div>(English Medium)</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Home